<template>
  <div class="Search">
    <transition name="fade" mode="out-in">
      <alert v-if="error" :content="alertContent" @confirm="closeAlert" />
    </transition>
    <div class="panel">
      <div class="Grid-row">
        <div class="header-title">Search for User</div>
      </div>
      <div class="Grid-row">
        <div class="Grid-column-4">
          <input
            v-model="userInput"
            :class="{ 'has-error': !userInput && error }"
            class="input"
            type="text"
            placeholder="Type here username, email or phone"
          />
        </div>
      </div>
      <div class="Grid-row">
        <button class="button button-success" @click="searchUser">
          Search User
        </button>
      </div>
      <div v-if="Object.keys(user).length > 0" class="Grid-row">
        <div class="Grid-column-12">
          <ul class="Search__list">
            <li class="Search__list-row search__list-row--header">
              <span class="Search__list-item">Id</span>
              <span class="Search__list-item">Name</span>
              <span class="Search__list-item">Username</span>
              <span class="Search__list-item">Gender</span>
              <span class="Search__list-item">Phone No.</span>
              <span class="Search__list-item">Email</span>
            </li>
            <li class="Search__list-row">
              <span class="Search__list-item">{{ user.id }}</span>
              <span class="Search__list-item">{{ user.name }}</span>
              <span class="Search__list-item">{{ user.username }}</span>
              <span class="Search__list-item">{{ user.gender }}</span>
              <span class="Search__list-item">{{ user.phoneNumber }}</span>
              <span class="Search__list-item">{{ user.email }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alert from '@/components/common/navigation/Alert';

export default {
  name: 'Search',
  components: {
    Alert,
  },
  data() {
    return {
      alertContent: 'User not found.',
    };
  },
  computed: {
    userInput: {
      get() {
        return this.$store.getters.userInput;
      },
      set(value) {
        this.$store.commit('setUserInput', { value: value });
      },
    },
    user: {
      get() {
        return this.$store.getters.user;
      },
    },
    error: {
      get() {
        return this.$store.getters.error;
      },
    },
  },
  methods: {
    searchUser() {
      if (!this.userInput) {
        this.error = true;
      } else {
        if (this.validateEmail(this.userInput)) {
          this.$store.dispatch('searchUserByEmail');
        } else if (this.validatePhoneNumber(this.userInput)) {
          this.$store.dispatch('searchUserByPhoneNumber');
        } else {
          this.$store.dispatch('searchUserByUserName');
        }
      }
    },
    closeAlert() {
      this.$store.commit('setError', false);
    },
    validateEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validatePhoneNumber(number) {
      // eslint-disable-next-line
      var re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
      return re.test(String(number).toLowerCase());
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/search/Search.scss';
</style>
